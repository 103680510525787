const defaultCartFragment = `fragment CartFragment on Cart {
  currency
  contents {
    itemCount
    nodes {
      key
      product {
        node {
          databaseId
          featuredImage {
            node {
              altText
              sizes(size: WOOCOMMERCE_THUMBNAIL)
              sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
            }
          }
          onSale
          name
          ... on SimpleProduct {
            regularPrice(format: FORMATTED)
            price(format: FORMATTED)
            salePrice(format: FORMATTED)
            stockQuantity
            stockStatus
          }
          ... on VariableProduct {
            regularPrice(format: FORMATTED)
            price(format: FORMATTED)
            salePrice(format: FORMATTED)
            stockQuantity
            stockStatus
          }
        }
      }
      quantity
      total(format: FORMATTED)
      subtotal(format: FORMATTED)
    }
    productCount
  }
  appliedCouponsData {
    code
    description
    discountAmount
  }
  subtotal(format: FORMATTED)
  shippingTotal(format: FORMATTED)
  needsShippingAddress
  chosenShippingMethods
  availableShippingMethods {
    supportsShippingCalculator
    packageDetails
    rates {
      label
      instanceId
      id
      cost
      methodId
    }
  }
  total(format: FORMATTED)
  feeAmmount
  nonBankTransferFee
  nonBankTransferFeeText
}`

const buildQueryParams = params => {
  return Object.entries(params)
    .filter(([_, value]) => value !== "") // Filter out empty string values
    .map(([key, value]) =>
      typeof value === "string" ? `${key}: "${value}"` : `${key}: ${value}`
    ) // Handle string and numeric values appropriately
    .join("\n    ") // Join with newlines and indentation for readability
}

export const productDefaultListingQuery = params => {
  const queryParams = buildQueryParams(params)
  return `query PropductListingandFilteration {
  productsFilterInit(
    ${queryParams}
    ) {
        pageCount
        currentPage
        endCursor
        total
        proData {
            id
            image_alt
            image_src
            image_srcset
            link_path
            name
            on_sale
            permalink
            price
            regularPrice
            salePrice
        }
    }
}`
}

export const productDefaultListingQuerySelections = params => {
  const queryParams = buildQueryParams(params)
  return `query PropductListingandFilteration {
  productsFilterInit(
    ${queryParams}
    ) {
        pageCount
        currentPage
        endCursor
        total
        proData {
            id
            image_alt
            image_src
            image_srcset
            link_path
            name
            on_sale
            permalink
            price
            regularPrice
            salePrice
        }
    } productsFilterWithAttribute(${queryParams}) {
        attributeData {
            label
            data {
                count
                name
                option
            }
        }
    } 
    minPriceMaxPrice(category: "${params.category}") {
        maxPriceVal
        minPriceVal
    }
    attributeRanges {
        mintage {
            max {
                id
                name
            }
            min {
                id
                name
            }
        }
        year {
            max {
                id
                name
            }
            min {
                id
                name
            }
        }
    }
}`
}

export const productDetailsQuery = slug => `query productDetails {
          product(id: "${slug}", idType: SLUG) {
      currency
      featuredImage {
        node {
          altText
          srcSet
          sourceUrl
        }
      }
      galleryImages(first: 100) {
        nodes {
          altText
          srcSet
          sourceUrl
        }
      }
      name
      coinSpecificationsData
      databaseId
      sku
      productCategories {
        nodes {
          name
          link
        }
      }
      productTags {
        nodes {
          name
          link
        }
      }
      onSale
      ... on SimpleProduct {
        salePrice(format: RAW)
        price
        regularPrice(format: RAW)
        stockQuantity
        stockStatus
      }
      ... on VariableProduct {
        salePrice(format: RAW)
        price
        regularPrice(format: RAW)
        stockQuantity
        stockStatus
      }
      description(format: RENDERED)
      attributes(first: 100) {
        nodes {
          label
          ... on GlobalProductAttribute {
            terms(first: 100) {
              nodes {
                name
                taxonomyName
                link
                slug
                uri
              }
            }
          }
        }
      }
      averageRating
      commentCount
      commentStatus
      reviews {        
      edges {
        rating
        node {
          author {
            node {
              name
              avatar {
                url
                height
                width
              }
            }
          }
          content(format: RENDERED)
          date
        }
      }
        averageRating
      }
      related(first: 3) {
        edges {
          node {
            name
            image {
              sourceUrl
              altText
              srcSet
            }
            databaseId
            onSale
            ... on SimpleProduct {
              salePrice(format: RAW)
              regularPrice(format: RAW)
                uri
            }
            ... on VariableProduct {
              salePrice(format: FORMATTED)
              regularPrice(format: RAW)
                uri
            }
          }
        }
      }
    }
          }`

export const getOrderDetails = id => `query GetOrderDetails {
  order(idType: DATABASE_ID, id: "${id}") {
    date
    dateCompleted
    orderNumber
    status
    lineItems {
      nodes {
        product {
          node {
            ... on SimpleProduct {
              id
              name
              uri
              stockStatus
              price(format: FORMATTED)
            }
          }
        }
        quantity
        productId
        total
        subtotal     
        metaData {
          key
          value
        }
      }  
    }
    subtotal(format: FORMATTED)
    discountTotal
    shippingTotal(format: FORMATTED)
    shippingLines {
      nodes {
        methodTitle
      }
    }      
    currency
    feeLines {
        nodes {
            name
            total
        }
    }
    paymentMethod
    paymentMethodTitle
    total(format: FORMATTED)
    currency
    customerNote
    
    billing {
      firstName
      lastName
      company
      address1
      address2
      city
      state
      postcode
      country
      phone
      email
    }
    shipping {
      firstName
      lastName
      company
      address1
      address2
      city
      state
      postcode
      country
      phone
      email
    }
  }
}`

//With Login
export const addProductToCart = (productId, quantity) => `mutation AddToCart {
    addToCart(input: {quantity: ${quantity}, productId: ${productId}}) {
      cart {
        ...CartFragment
      }
    }
  }
  ${defaultCartFragment}  
  `

//Without Login
export const addProductToCartNew = (
  productId,
  quantity,
  uniqueSessionId
) => `mutation AddToCartNew {
      addToCartNew(input: {quantity: ${quantity}, productId: ${productId}, sessionId: "${uniqueSessionId}"}) {
        cart {
          ...CartFragment
        }
        sessionId
      }
    }
  ${defaultCartFragment}  
  `

export const getCartIteams = () => ` query GetCart {
          cart(recalculateTotals: true) {
            ...CartFragment
          }
        }
  ${defaultCartFragment}`

export const getCartIteamsNew = (
  curentCurrency,
  sessionId
) => `mutation MyMutation {
  getCartNew(
    input: {sessionId: "${sessionId}", curentCurrency: "${curentCurrency}"}
  ) {
    cart {
      ...CartFragment
    }
    sessionId
  }
}
${defaultCartFragment}
`

//With Login
export const updateCartItemQtyAndAmout = (
  key,
  qty
) => `mutation updateItemQuantities {
  updateItemQuantities(input: {items: {key: "${key}",
     quantity: ${qty}}}) {
    cart {
        ...CartFragment
      }
    }
  }
  
  ${defaultCartFragment}  
`

//Without Login
export const updateCartItemQtyAndAmoutNew = (
  key,
  qty
) => `mutation UpdateItemQuantitiesNew {
  updateItemQuantitiesNew(input: {productId: "${key}",
     quantity: ${qty}}) {
    cart {
        ...CartFragment
      }
      sessionId
    }
  }
  
 ${defaultCartFragment}  
`
//With Login
export const removeSingleCartItemUsingProduct =
  key => `mutation removeItemsFromCart {
  removeItemsFromCart(input: {keys: "${key}"
    }) {
    cart {
          ...CartFragment
      }
    }
  }
  
  ${defaultCartFragment}  
`

//Without Login
export const removeSingleCartItemUsingProductNew = (
  key,
  uniqueSessionId
) => `mutation RemoveCartNew  {
  removeCartNew (input: {productId: "${key}", sessionId: "${uniqueSessionId}"
    }) {
    cart {
      ...CartFragment
      }
      sessionId
    }
  }
  
 ${defaultCartFragment}  
`

export const applayCouponCodeQuery = code => `mutation ApplyCoupon {
  applyCoupon(input: {code: "${code}"}) {
    applied {
      code
      description
      discountAmount(excludeTax: true, format: FORMATTED)
    }
      cart {
        contents {
        itemCount
        nodes {
          key
          product {
            node {
              databaseId
              featuredImage {
                node {
                  altText
                  sizes(size: WOOCOMMERCE_THUMBNAIL)
                  sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
                }
              }
              onSale
              name
              ... on SimpleProduct {
                regularPrice(format: FORMATTED)
                price(format: FORMATTED)
                salePrice(format: FORMATTED)
                stockQuantity
                stockStatus
              }
              ... on VariableProduct {
                regularPrice(format: FORMATTED)
                price(format: FORMATTED)
                salePrice(format: FORMATTED)
                stockQuantity
                stockStatus
              }
            }
          }
          quantity
          total(format: FORMATTED)
          subtotal(format: FORMATTED)
        }
        productCount
      }
      appliedCouponsData {
        code
        description
        discountAmount
      }
      subtotal(format: FORMATTED)
      shippingTotal(format: FORMATTED)
      needsShippingAddress
      chosenShippingMethods
      availableShippingMethods {
        supportsShippingCalculator
        packageDetails
        rates {
          label
          instanceId
          id
          cost
          methodId
        }
      }
      total(format: FORMATTED)
    }
  }
}`

export const applayCouponCodeQueryUsingSession = (
  code,
  sessionId
) => `mutation MyMutation {
  applyCouponNew(input: {code: "${code}", sessionId: "${sessionId}"}) {
    cart{
...CartFragment
    }
    sessionId
  }
}
 ${defaultCartFragment}  
`

export const removeCouponCodeQuery = code => `mutation RemoveCoupons {
    removeCoupons(input: { codes: "${code}" }) {
        cart {
             contents {
              itemCount
              nodes {
                key
                product {
                  node {
                    databaseId
                    featuredImage {
                      node {
                        altText
                        sizes(size: WOOCOMMERCE_THUMBNAIL)
                        sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
                      }
                    }
                    onSale
                    name
                    ... on SimpleProduct {
                      regularPrice(format: FORMATTED)
                      price(format: FORMATTED)
                      salePrice(format: FORMATTED)
                      stockQuantity
                      stockStatus
                    }
                    ... on VariableProduct {
                      regularPrice(format: FORMATTED)
                      price(format: FORMATTED)
                      salePrice(format: FORMATTED)
                      stockQuantity
                      stockStatus
                    }
                  }
                }
                quantity
                total(format: FORMATTED)
                subtotal(format: FORMATTED)
              }
              productCount
            }
            appliedCouponsData {
              code
              description
              discountAmount
            }
            subtotal(format: FORMATTED)
            shippingTotal(format: FORMATTED)
            needsShippingAddress
            chosenShippingMethods
            availableShippingMethods {
                supportsShippingCalculator
                packageDetails
                rates {
                    label
                    instanceId
                    id
                    cost
                    methodId
                }
            }
            total(format: FORMATTED)
        }
    }
}`

export const removeCouponCodeQueryUsingSession = (
  code,
  sessionId
) => `mutation MyMutation {
  removeCouponNew(input: {codes: "${code}", sessionId: "${sessionId}"}) {
    cart{
...CartFragment
    }
    sessionId
  }
}
   ${defaultCartFragment}  
`

export const shippingAddressQuery = address => `mutation UpdateShippingAddress {
    updateShippingAddress(
      input: {address: {city: "${address.city}", country: "${address.country}", state: "${address.state}", postcode: "${address.postcode}"}}
    ) {
      cart {
      ...CartFragment
    
      }
    }
  }
   ${defaultCartFragment}  
  `

export const updateShippingAddressQueryBasedOnSession = (
  address,
  sessionId,
  curentCurrency
) => `mutation MyMutation {
  updateShippingAddressNew(
    input: {sessionId: "${sessionId}", postcode: "${address.postcode}", curentCurrency: "${curentCurrency}", country: "${address.country}", clientMutationId: "", city: "${address.city}", state: "${address.state}"}
  ) {
    cart {
      ...CartFragment
    }
    sessionId
    city
    country
    postcode
    state
  }
}

${defaultCartFragment}  
`

export const updateShippingMethodQuery =
  shippingMethods => `mutation updateShippingMethod {
  updateShippingMethod(input: {shippingMethods: "${shippingMethods}"}) {
    cart {
        ...CartFragment
      }
    }
  }
  
 ${defaultCartFragment}  
`

export const updateShippingMethodQueryUsingSession = (
  shippingMethods,
  sessionId
) => `mutation MyMutation {
  updateShippingMethodNew(
    input: {sessionId: "${sessionId}", shippingMethods: "${shippingMethods}"}
  ) {
    cart {
      ...CartFragment
    }
    sessionId
  }
}
${defaultCartFragment}  
`

export const applyPointsDiscountQuery =
  points => `mutation applyPointsDiscount {
    applyPointsDiscount(input: { points: ${points} }) {
        message
        success
        shippingTotal
        points_Discount
        cart {
            ...CartFragment
        }
    }
}
 ${defaultCartFragment}`

export const getPointsByUserIdQuery = () => `
        query getPointsByUserId($userId: ID!) {
          pointsByUserId(userId: $userId) {
            totalPoits
            totalRemainPoits
            cart_subtotal_pointData {
              cart_subtotal_point
              date
            }
            pro_conversion_pointsData {
              date
              pro_conversion_points
              refered_order_id
            }
          }
        }
    `

export const PaymentGatewaysQuery = () => `query PaymentGateways {
  paymentGateways {
    nodes {
      title
      icon
      description
      id
    }
  }
}`

export const mutationCheckoutQuery = payload => `mutation checkout {
    checkout(
        input: {
            customerNote: "${payload.customerNote}"
            billing: {
                address1: "${payload.billing.address1}"
                address2: "${payload.billing.address2}"
                city: "${payload.billing.city}"
                company: "${payload.billing.company}"
                country: ${payload.billing.country}
                email: "${payload.billing.email}"
                firstName: "${payload.billing.firstName}"
                lastName: "${payload.billing.lastName}"
                phone: "${payload.billing.phone}"
                postcode: "${payload.billing.postcode}"
                state: "${payload.billing.state}"
                overwrite: true
            }
            paymentMethod: "${payload.paymentMethod}"
            shipping: {
                address1: "${payload.shipping.address1}"
                address2: "${payload.shipping.address2}"
                city: "${payload.shipping.city}"
                company: "${payload.shipping.company}"
                country: ${payload.shipping.country}
                email: "${payload.shipping.email}"
                firstName: "${payload.shipping.firstName}"
                lastName: "${payload.shipping.lastName}"
                phone: "${payload.shipping.phone}"
                postcode: "${payload.shipping.postcode}"
                state: "${payload.shipping.state}"
                overwrite: true
            }
            metaData: { key: "mailchimp_woocommerce_is_subscribed", value: "${payload.subscribeToNewsletter}" }
            shipToDifferentAddress: ${payload.shipToDifferentAddress}
            shippingMethod: "${payload.shippingMethod}"
            transactionId: ""
        }
    ) {
        result
        order {
          orderNumber
          date
          total(format: FORMATTED)
          paymentMethodTitle      
          lineItems {
            nodes {
              product {
                node {
                  ... on SimpleProduct {
                    id
                    name
                    uri
                    stockStatus
                  }
                }
              }
              quantity
              productId
              total
            }
          }
          subtotal(format: FORMATTED)
          discountTotal
          shippingTotal(format: FORMATTED)
          shippingLines {
            nodes {
              methodTitle
            }
          }
          feeLines(first: 100) {
              nodes {
                  amount
                  id
                  name
                  total
              }
          }
          paymentMethod
          paymentMethodTitle
          total(format: FORMATTED)
          customerNote
          billing {
            address1
            address2
            city
            company
            country
            email
            firstName
            lastName
            phone
            postcode
            state
          }
          shipping {
            address1
            address2
            city
            company
            country
            state
            postcode
            firstName
            lastName
          }
          currency
        }
    }
}
`

export const applyInsuranceFeeQuery = value => `mutation ApplyInsuranceFee {
  applyInsuranceFee(input: {insuranceFee: ${value}}) {
    message
    feeAmmount
  }
}`

export const applyInsuranceFeeQueryUsingSession = (
  value,
  sessionId
) => `mutation MyMutation2 {
  applyInsuranceFeeNew(
    input: {sessionId: "${sessionId}", insuranceFee: ${value}}
  ) {
    cart {
      ...CartFragment
    }
    sessionId
    message
  }
}
  ${defaultCartFragment}  
  `

export const addLocalCartProductsFromLocalStorageToLogedInUserCartQuery =
  items => {
    const formattedItems = items
      .map(
        item => `{ productId: ${item.productId}, quantity: ${item.quantity} }`
      )
      .join(", ")
    return `mutation MyMutation {
  addCartItems(input: {items: [${formattedItems}]}) {
    cart {
    contents {
        itemCount
        nodes {
          key
          product {
            node {
              databaseId
              featuredImage {
                node {
                  altText
                  sizes(size: WOOCOMMERCE_THUMBNAIL)
                  sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
                }
              }
              onSale
              name
              ... on SimpleProduct {
                regularPrice(format: FORMATTED)
                price(format: FORMATTED)
                salePrice(format: FORMATTED)
                stockQuantity
                stockStatus
              }
              ... on VariableProduct {
                regularPrice(format: FORMATTED)
                price(format: FORMATTED)
                salePrice(format: FORMATTED)
                stockQuantity
                stockStatus
              }
            }
          }
          quantity
          total(format: FORMATTED)
          subtotal(format: FORMATTED)
        }
        productCount
      }
      appliedCouponsData {
        code
        description
        discountAmount
      }
      subtotal(format: FORMATTED)
      shippingTotal(format: FORMATTED)
      needsShippingAddress
      chosenShippingMethods
      availableShippingMethods {
        supportsShippingCalculator
        packageDetails
        rates {
          label
          instanceId
          id
          cost
          methodId
        }
      }
      total(format: FORMATTED)
    }
  }
}`
  }

export const addLocalCartProductsToWPSessionUsingUniqueMutationIdQuery = (
  items,
  uniqueId
) => {
  const formattedItems = items
    .map(item => `{ productId: ${item.productId}, quantity: ${item.quantity} }`)
    .join(", ")
  return `mutation MyMutation {
  addCartItems(input: {clientMutationId: "${uniqueId}", items: [${formattedItems}]}) {
    cart {
    contents {
        itemCount
        nodes {
          key
          product {
            node {
              databaseId
              featuredImage {
                node {
                  altText
                  sizes(size: WOOCOMMERCE_THUMBNAIL)
                  sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
                }
              }
              onSale
              name
              ... on SimpleProduct {
                regularPrice(format: FORMATTED)
                price(format: FORMATTED)
                salePrice(format: FORMATTED)
                stockQuantity
                stockStatus
              }
              ... on VariableProduct {
                regularPrice(format: FORMATTED)
                price(format: FORMATTED)
                salePrice(format: FORMATTED)
                stockQuantity
                stockStatus
              }
            }
          }
          quantity
          total(format: FORMATTED)
          subtotal(format: FORMATTED)
        }
        productCount
      }
      appliedCouponsData {
        code
        description
        discountAmount
      }
      subtotal(format: FORMATTED)
      shippingTotal(format: FORMATTED)
      needsShippingAddress
      chosenShippingMethods
      availableShippingMethods {
        supportsShippingCalculator
        packageDetails
        rates {
          label
          instanceId
          id
          cost
          methodId
        }
      }
      total(format: FORMATTED)
    }
  }
}`
}

export const mutationCheckoutQueryPaypal =
  payload => `mutation checkoutWithPaypal  {
  processCustomCheckout(
      input: {
          customerNote: "${payload.customerNote}"
          billing: {
              address1: "${payload.billing.address1}"
              address2: "${payload.billing.address2}"
              city:     "${payload.billing.city}"
              company:  "${payload.billing.company}"
              country:  "${payload.billing.country}"
              email:    "${payload.billing.email}"
              firstName:"${payload.billing.firstName}"
              lastName: "${payload.billing.lastName}"
              phone:    "${payload.billing.phone}"
              postcode: "${payload.billing.postcode}"
              state:    "${payload.billing.state}"
              overwrite: true
          }
          paymentMethod: "${payload.paymentMethod}"
          shipping: {
              address1: "${payload.shipping.address1}"
              address2: "${payload.shipping.address2}"
              city:     "${payload.shipping.city}"
              company:  "${payload.shipping.company}"
              country:  "${payload.shipping.country}"
              firstName:"${payload.shipping.firstName}"
              lastName: "${payload.shipping.lastName}"
              postcode: "${payload.shipping.postcode}"
              state:    "${payload.shipping.state}"
              overwrite: true
          }
          metaData: { key: "mailchimp_woocommerce_is_subscribed", value: "${payload.subscribeToNewsletter}" }
          shipToDifferentAddress: ${payload.shipToDifferentAddress}
          shippingMethod:        "${payload.shippingMethod}"
          transactionId:         "${payload.transactionId}"
          paypal_intent:         "${payload.paypal_intent}"
          paypal_order_id:       "${payload.paypal_order_id}"
          paypal_payment_source: "${payload.paypal_payment_source}"
          paypal_status:         "${payload.paypal_status}"
      }
  ) {
      success
      order {
        orderNumber
        date
        total(format: FORMATTED)
        paymentMethodTitle      
        lineItems {
          nodes {
            product {
              node {
                ... on SimpleProduct {
                  id
                  name
                  uri
                  stockStatus
                }
              }
            }
            quantity
            productId
            total
          }
        }
        subtotal(format: FORMATTED)
        discountTotal
        shippingTotal(format: FORMATTED)
        shippingLines {
          nodes {
            methodTitle
          }
        }
        feeLines(first: 100) {
            nodes {
                amount
                id
                name
                total
            }
        }
        paymentMethod
        paymentMethodTitle
        total(format: FORMATTED)
        customerNote
        billing {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          address2
          city
          company
          country
          state
          postcode
          firstName
          lastName
        }
        currency
      }
  }
}
`

export const mutationCheckoutQueryForBankTransferUsingSession = (
  payload,
  sessionId,
  wantsToCreateAccount,
  password
) => {
  const accountInput = wantsToCreateAccount
    ? `account: {
          username: "${payload.billing.email}",
          email: "${payload.billing.email}",
          password: "${password}"
      }`
    : ""

  return `mutation MyMutation { 
  processCustomCheckoutNew(
    input: {
      sessionId: "${sessionId}",
      customerNote: "${payload.customerNote}",
      billing: {
          address1: "${payload.billing.address1}",
          address2: "${payload.billing.address2}",
          city:     "${payload.billing.city}",
          company:  "${payload.billing.company}",
          country:  "${payload.billing.country}",
          email:    "${payload.billing.email}",
          firstName:"${payload.billing.firstName}",
          lastName: "${payload.billing.lastName}",
          phone:    "${payload.billing.phone}",
          postcode: "${payload.billing.postcode}",
          state:    "${payload.billing.state}",
          overwrite: true
      },
      paymentMethod: "${payload.paymentMethod}",
      transactionId: "", 
      metaData: [{ key: "mailchimp_woocommerce_is_subscribed", value: "${payload.subscribeToNewsletter}"}],
      shipToDifferentAddress: ${payload.shipToDifferentAddress},
      shippingMethod: "${payload.shippingMethod}",
      shipping: {
          address1: "${payload.shipping.address1}",
          address2: "${payload.shipping.address2}",
          city:     "${payload.shipping.city}",
          company:  "${payload.shipping.company}",
          country:  "${payload.shipping.country}",
          firstName:"${payload.shipping.firstName}",
          lastName: "${payload.shipping.lastName}",
          postcode: "${payload.shipping.postcode}",
          state:    "${payload.shipping.state}",
          overwrite: true
      }, 
      ${accountInput}
    }
    ) {
      orderDate
      sessionId
      result
      error
      order {
        orderNumber
        date
        total(format: FORMATTED)
        paymentMethodTitle
        lineItems {
          nodes {
            product {
              node {
                ... on SimpleProduct {
                  id
                  name
                  uri
                  stockStatus
                }
              }
            }
            quantity
            productId
            total
          }
        }
        subtotal(format: FORMATTED)
        discountTotal
        shippingTotal(format: FORMATTED)
        shippingLines {
          nodes {
            methodTitle
          }
        }
        feeLines(first: 100) {
          nodes {
            amount
            id
            name
            total
          }
        }
        paymentMethod
        paymentMethodTitle
        total(format: FORMATTED)
        customerNote
        billing {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          address2
          city
          company
          country
          state
          postcode
          firstName
          lastName
        }
      }
    }
  }`
}

export const mutationCheckoutQueryForPaypalUsingSession = (
  payload,
  sessionId,
  wantsToCreateAccount,
  password
) => {
  const accountInput = wantsToCreateAccount
    ? `account: {
          username: "${payload.billing.email}",
          email: "${payload.billing.email}",
          password: "${password}"
      }`
    : ""

  return `mutation MyMutation { 
  processCustomCheckoutNew(
    input: {
      sessionId: "${sessionId}",
      customerNote: "${payload.customerNote}",
      billing: {
          address1: "${payload.billing.address1}",
          address2: "${payload.billing.address2}",
          city:     "${payload.billing.city}",
          company:  "${payload.billing.company}",
          country:  "${payload.billing.country}",
          email:    "${payload.billing.email}",
          firstName:"${payload.billing.firstName}",
          lastName: "${payload.billing.lastName}",
          phone:    "${payload.billing.phone}",
          postcode: "${payload.billing.postcode}",
          state:    "${payload.billing.state}",
          overwrite: true
      },
      paymentMethod: "${payload.paymentMethod}",
      transactionId: "${payload.transactionId}",
      paypal_intent: "${payload.paypal_intent}",
      paypal_order_id: "${payload.paypal_order_id}",
      paypal_payment_source: "${payload.paypal_payment_source}",
      paypal_status: "${payload.paypal_status}",
      metaData: [{ key: "mailchimp_woocommerce_is_subscribed", value: "${payload.subscribeToNewsletter}"}],
      shipToDifferentAddress: ${payload.shipToDifferentAddress},
      shippingMethod: "${payload.shippingMethod}",
      shipping: {
          address1: "${payload.shipping.address1}",
          address2: "${payload.shipping.address2}",
          city:     "${payload.shipping.city}",
          company:  "${payload.shipping.company}",
          country:  "${payload.shipping.country}",
          firstName:"${payload.shipping.firstName}",
          lastName: "${payload.shipping.lastName}",
          postcode: "${payload.shipping.postcode}",
          state:    "${payload.shipping.state}",
          overwrite: true
      }, 
      ${accountInput}
    }
    ) {
      orderDate
      sessionId
      result
      error
      order {
        orderNumber
        date
        total(format: FORMATTED)
        paymentMethodTitle
        lineItems {
          nodes {
            product {
              node {
                ... on SimpleProduct {
                  id
                  name
                  uri
                  stockStatus
                }
              }
            }
            quantity
            productId
            total
          }
        }
        subtotal(format: FORMATTED)
        discountTotal
        shippingTotal(format: FORMATTED)
        shippingLines {
          nodes {
            methodTitle
          }
        }
        feeLines(first: 100) {
          nodes {
            amount
            id
            name
            total
          }
        }
        paymentMethod
        paymentMethodTitle
        total(format: FORMATTED)
        customerNote
        billing {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          address2
          city
          company
          country
          state
          postcode
          firstName
          lastName
        }
      }
    }
  }`
}

export const productBackorderAvailability =
  slug => `query productAvailabilityBySlug {
    productAvailabilityBySlug(
      slug: "${slug}"
    )
  }
  `

//Without Login
export const applyFeesOnPaymentMethodChangeNew = (
  paymentMethod,
  uniqueSessionId
) => `mutation ApplyFeesOnPaymentMethodChangeNew {
    applyFeesOnPaymentMethodChangeNew(
      input: {paymentMethod: "${paymentMethod}", sessionId: "${uniqueSessionId}"}
    ) {
      nonBankTransferFeeText
      nonBankTransferFee
      cart {
        ...CartFragment
      }
      sessionId
    }
  }
  ${defaultCartFragment}
`

//With Login
export const applyFeesOnPaymentMethodChange =
  paymentMethod => `mutation ApplyFeesOnPaymentMethodChange {
    applyFeesOnPaymentMethodChange(
      input: {paymentMethod: "${paymentMethod}", }
    ) {
      nonBankTransferFeeText
      nonBankTransferFee
      cart {
        ...CartFragment
      }
    }
  }
  ${defaultCartFragment}
`
